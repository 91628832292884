import React from 'react';
import UserInfo from './UserInfo';
import RoomList from './RoomList';
import styled from 'styled-components';

const SidebarStyled = styled.div`
  background: #3f0e40;
  color: white;
  height: 100%;
`;

export default function Sidebar() {
  return (
    <SidebarStyled>
      <UserInfo />
      <RoomList />
    </SidebarStyled>
  );
}
