import React from 'react';
import { Collapse, Typography, Button } from 'antd';
import styled from 'styled-components';
import { PlusSquareOutlined } from '@ant-design/icons';
import { AppContext } from '../../Context/AppProvider';

const { Panel } = Collapse;

const Wrapper = styled.div`
  &&& {
    height: calc(100% - 57px);

    .ant-collapse {
      height: 100%;

      &-content-box {
        overflow-y: auto;
        height: 100%;
      }

      &-header,
      p {
        color: white;
      }

      &-content-box {
        padding: 0 40px;

        @media screen and (max-width: 576px) {
          padding: 0 16px;
        }
      }

      &-item {
        height: 100%;
      }

      &-content {
        height: calc(100% - 46px);

        @media screen and (max-width: 576px) {
          height: calc(100% - 68px);
        }
      }
    }
  }
`;

const AddRoomStyled = styled(Button)`
  color: white;
  padding: 0;

  &:hover,
  &:focus,
  &:active {
    color: #cecece;
  }
`;

const LinkStyled = styled(Typography.Link)`
  display: block;
  margin-bottom: 5px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default function RoomList() {
  const { rooms, setIsAddRoomVisible, setSelectedRoomId } =
    React.useContext(AppContext);

  const handleAddRoom = () => {
    setIsAddRoomVisible(true);
  };

  return (
    <Wrapper>
      <Collapse ghost defaultActiveKey={['1']}>
        <Panel header='Danh sách các phòng' key='1'>
          {rooms.map((room) => (
            <LinkStyled
              key={room.id}
              onClick={() => setSelectedRoomId(room.id)}
            >
              {room.name}
            </LinkStyled>
          ))}
          <AddRoomStyled
            type='text'
            icon={<PlusSquareOutlined />}
            onClick={handleAddRoom}
          >
            Thêm phòng
          </AddRoomStyled>
        </Panel>
        <LinkStyled></LinkStyled>
      </Collapse>
    </Wrapper>
  );
}
