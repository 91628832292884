import React from 'react';
import { Row, Col } from 'antd';
import Sidebar from './Sidebar';
import ChatWindow from './ChatWindow';

export default function ChatRoom() {
  return (
    <Row style={{ height: '100%' }}>
      <Col xs={8} sm={6} style={{ height: '100%' }}>
        <Sidebar />
      </Col>
      <Col xs={16} sm={18} style={{ height: '100%' }}>
        <ChatWindow />
      </Col>
    </Row>
  );
}
