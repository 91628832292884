import React from 'react';
import { Row, Col, Button, Typography } from 'antd';
import firebase, { auth } from '../../firebase/config';
import { addDocument, generateKeywords } from '../../firebase/services';
import { FacebookOutlined, GoogleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const fbProvider = new firebase.auth.FacebookAuthProvider();
const googleProvider = new firebase.auth.GoogleAuthProvider();

export default function Login() {
  const handleLogin = async (provider) => {
    const { additionalUserInfo, user } = await auth.signInWithPopup(provider);

    if (additionalUserInfo?.isNewUser) {
      addDocument('users', {
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        uid: user.uid,
        providerId: additionalUserInfo.providerId,
        keywords: generateKeywords(user.displayName?.toLowerCase()),
      });
    }
  };

  return (
    <div>
      <Row justify='center' style={{ marginTop: 50 }}>
        <Col span={8}>
          <Title style={{ textAlign: 'center' }} level={3}>
            Fun Chat
          </Title>
          <Text
            style={{ textAlign: 'center', display: 'block', marginBottom: 5 }}
          >
            Đăng nhập
          </Text>
          <Button
            block
            style={{ marginBottom: 5 }}
            onClick={() => handleLogin(googleProvider)}
            icon={<GoogleOutlined />}
          >
            Google
          </Button>
          <Button
            block
            onClick={() => handleLogin(fbProvider)}
            icon={<FacebookOutlined />}
          >
            Facebook
          </Button>
        </Col>
      </Row>
    </div>
  );
}
